<template>
  <svg style="display: none;" xmlns="http://www.w3.org/2000/svg">
    <symbol id="flash-success-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="flash-info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="flash-error-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>
  <div v-for="(message, index) in $store.getters['messages/all']"
       :key="index"
       :class="'flash-' + message.level"
       class="rounded-lg flex justify-between p-2 top-0 w-80 md:w-auto left-1/2 -translate-x-1/2 fixed mt-3 z-50">
    <div class="flex items-center mr-4 ml-2">
      <svg height="24" role="img" width="24">
        <use :xlink:href="'#flash-'+message.level+'-fill'"/>
      </svg>
    </div>
    <div class="flex items-center">{{ $i18n.t(message.text) }}</div>
    <button class="text-3xl flex items-center ml-4 px-2" style="width: auto"
            @click="$store.commit('messages/remove', index)">
      <div style="margin-top: -3px">&times;</div>
    </button>
  </div>
</template>

<style>
.flash-info {
  background-color: rgb(211, 225, 252);
  color: rgb(25 67 147);
}

.flash-success {
  background-color: rgb(213, 230, 222);
  color: rgb(37 80 53);
}

.flash-error {
  background-color: rgb(243, 216, 218);
  color: rgb(121 40 44);
}
</style>
