const messages = {
    namespaced: true,

    state: () => ({
        messages: []
    }),

    mutations: {
        add_message(state, {message, level}) {
            for (const {text} of state.messages) {
                if (text === message) {
                    return;
                }
            }
            state.messages = [{
                level,
                'text': message
            }];
        },

        remove(state, index) {
            state.messages.splice(index, 1);
        }
    },

    getters: {
        all: state => state.messages
    }
};

export default messages;
