import {createApp, h} from "vue";
import App from './App.vue';
import router from "./router";
import store from './store';
import messages from './translations';
import {createI18n} from 'vue-i18n';
import './assets/build.css';

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

const app = createApp({
    render: () => h(App),
});

app.use(router);
app.use(store);

const i18n = createI18n({
    locale: store.getters['account/selected_locale'],
    messages
});

app.use(i18n);

app.mount('#mycharacter');
