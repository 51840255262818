<template>
  <div id="chat-window"
       :class="{'hidden': !$store.getters['chat/is_visible']}"
       class="fixed bg-white p-2 z-40 right-0 bottom-0 shadow-chat overflow-scroll no-scrollbar"
       style="border-radius: 0.75rem 0 0.75rem 0.75rem; max-width: 350px; min-width: 350px;">
    <div v-if="$store.getters['chat/current_room']" class="flex flex-col h-full">
      <div class="flex justify-between items-center my-4 px-4">
        <div class="cursor-pointer text-2xl"
             @click="$store.commit('chat/unset_current_room')">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div @click="go_to_user_detail"
             class="font-bold text-xl text-blue cursor-pointer">
          {{ $store.getters['chat/target_username']($store.getters['chat/current_room'].target) }}
        </div>
        <div class="cursor-pointer font-light"
             @click="$store.commit('chat/toggle')"><i class="fas fa-times text-3xl"></i></div>
      </div>
      <div id="chat-history" class="overflow-scroll no-scrollbar flex flex-grow flex-col flex-nowrap">
        <div v-for="(message, index) in messages" :key="index">
          <div v-if="message.header" class="text-2xl font-bold text-tan">{{
              message.header
            }}
          </div>
          <div :class="get_position(message)" class="flex">
            <div :class="get_bg_color(message)" class="flex flex-col m-2 px-3 pt-3 pb-1 rounded-xl w-3/4">
              {{ message.text }}
              <div class="text-right text-sm">
                {{ format_date(message.created_at) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$store.getters['chat/current_room_approved'] && $store.getters['chat/can_approve_current_room']()"
           class="flex justify-center items-center mt-3">
        <button class="py-4 bg-white text-blue rounded-xl border-blue mr-8" style="width: 125px"
                @click="change_status_of_current_room(REJECT)">
          {{ $t('chat.reject') }}
        </button>
        <button class="py-4 bg-blue text-white rounded-xl" style="width: 125px"
                @click="change_status_of_current_room(APPROVE)">
          {{ $t('chat.approve') }}
        </button>
      </div>
      <div v-else class="p-2 grid grid-cols-12 flex items-center">
        <div class="col-span-10">
          <textarea :disabled="!$store.getters['chat/current_room_approved']"
                    :placeholder="$t($store.getters['chat/awaiting_approval_placeholder_label'], { username:
                    $store.getters['chat/target_username']($store.getters['chat/current_room'].target) })"
                    :value="message"
                    class="bg-lightgray gray-border rounded-xl p-1 w-full"
                    @input="change_text($event)"></textarea>
        </div>
        <div class="col-span-2 flex text-xl items-center text-white bg-blue justify-center pr-1 ml-2 cursor-pointer"
             style="border-radius: 50%; width: 40px; height: 40px;"
             @click="send_message()">
          <i class="far fa-paper-plane"></i>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-between items-center mt-4 mb-6 px-4">
        <div class="text-2xl font-bold">Chat</div>
        <div class="cursor-pointer font-light"
             @click="$store.commit('chat/toggle')"><i class="fas fa-times text-3xl"></i></div>
      </div>
      <hr class="mb-0">
      <div v-for="(chat_room, index) in $store.getters['chat/rooms']" :key="index">
        <div class="grid grid-cols-12 px-2 py-3 mb-0 cursor-pointer"
             @click="set_current_room(chat_room)">
          <div class="col-span-3 flex justify-center items-center">
            <img v-if="$store.getters['chat/target_picture'](chat_room.target)"
                 :src="$store.getters['common/baseURL'] + $store.getters['chat/target_picture'](chat_room.target)"
                 class="object-cover"
                 style="height: 70px; width: 70px; border-radius: 50%"/>
            <div v-else style="height: 70px; width: 70px; border-radius: 50%;"
               class="bg-gray-300 flex items-center justify-center mx-auto">
              <i class="fas fa-user text-white text-3xl"></i>
            </div>
          </div>
          <div class="col-span-7 flex justify-center items-center">
            <div>
              {{ $store.getters['chat/target_username'](chat_room.target) }}
            </div>
          </div>
          <div v-if="chat_room.target.status === 'awaiting_approval'"
               class="col-span-2 flex justify-center items-center text-2xl"
               style="color: rgb(109, 134, 192)">
            <i class="fas fa-hourglass-half"></i>
          </div>
          <div v-else-if="$store.getters['chat/has_new_messages'](chat_room)" class="col-span-2 flex justify-center items-center text-xl"
               style="color: rgb(109, 134, 192)">
            <i class="far fa-comment-dots"></i>
          </div>
        </div>
        <hr class="my-0">
      </div>
      <div v-if="!$store.getters['chat/rooms'].length" class="mt-5 text-center flex flex-col">
        <div class="flex justify-between">
          <img class="h-12" src="../assets/wiggle2.png"/>
          <img class="h-12" src="../assets/wiggle3.png"/>
        </div>
        <h2 class="mt-5 mb-2">{{ $t('chat.emptyHint.title')}}</h2>
        <p class="mb-2">
          {{ $t('chat.emptyHint.subtitle') }}
        </p>
        <router-link to="/compare-characters"
                     @click="on_find_people_click()"
                     class="p-3 py-4 bg-blue text-white rounded-xl mx-auto button-lg hover:text-white no-underline">
          {{ $t('chat.emptyHint.peopleButton') }}
        </router-link>
        <div class="flex justify-center mt-5">
          <img class="h-8" src="../assets/wiggle1.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.shadow-chat {
  box-shadow: -10px 0 10px 0px rgb(0 0 0 / 0.1)
}

.gray-border {
  border: 1px solid rgb(227, 227, 227);
}

.text-tan {
  color: rgb(163, 135, 155);
}
</style>

<script>
import axios from '../axios';
import consumer from '../websocketConsumer.js';
import store from "@/store";

export default {
  data() {
    return {
      APPROVE: 'approve',
      REJECT: 'reject',
      message: '',
      last_date_string: null,
      messages: []
    }
  },
  watch: {
    '$store.state.chat.current_user_from_user_detail_page': {
      handler: function(username) {
        if(username === null) {
          return;
        }
        const room = this.$store.getters['chat/room_for'](username);
        this.set_current_room(room);
        this.$store.commit('chat/set_chat_visible');
      },
      deep: true
    }
  },
  mounted() {
    consumer.register('chat:approve', this.on_approve);
    consumer.register('chat:reject', this.on_reject);
    consumer.register('chat:create', this.on_create);
    consumer.register('chat:message', this.on_message);
    this.position_chat();
    window.addEventListener('resize', this.position_chat);
    if(this.$store.getters['chat/current_room']) {
      this.set_current_room(this.$store.getters['chat/current_room']);
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.position_chat);
  },
  methods: {
    position_chat() {
      const chatWindow = document.getElementById('chat-window');
      const top = document.getElementById('menu').getBoundingClientRect().height;
      chatWindow.style.top = top + 'px';

      const right = window.innerWidth - document.getElementById('mycharacter').getBoundingClientRect().right;
      chatWindow.style.right = right + 1 + 'px';
    },
    set_current_room(chat_room) {
      this.$store.commit('chat/set_current_room', {chat_room});
      this.update_last_visit();
      const messages = chat_room.messages;

      let last_date_string = null;
      for (const message of messages) {
        const locale_string = new Date(message.created_at).toLocaleDateString();
        if (locale_string !== last_date_string) {
          message.header = locale_string;
          last_date_string = locale_string;
        }
      }

      this.messages = messages;
      this.scroll_chat_down();
    },
    scroll_chat_down() {
      this.$nextTick(() => {
        const history = document.getElementById('chat-history');
        if (history && history.lastElementChild) {
          history.lastElementChild.scrollIntoView();
        }
      });
    },
    format_date(date_str) {
      const date = new Date(date_str);
      return date.toLocaleTimeString();
    },
    send_message() {
      this.message = this.message.trim();
      if (this.message.length === 0) {
        return;
      }
      if (this.message[this.message.length - 1] === '\n') {
        this.message = this.message.slice(0, -1);
      }

      consumer.send('chat:message', {
        message: this.message,
        chat_room_id: this.$store.getters['chat/current_room'].id
      });
      this.message = '';
    },
    change_text(event) {
      this.message = event.target.value;
      if (this.message.length > 1 && this.message[this.message.length - 1] === '\n') {
        this.send_message();
      }
    },
    on_message(data) {
      this.$store.commit('chat/add_message', {
        message: data.message
      });
      if (this.$store.getters['chat/is_visible'] &&
          this.$store.getters['chat/current_room'] &&
          this.$store.getters['chat/current_room'].id === data.message.chat_room_id) {
        this.update_last_visit();
      }
      this.scroll_chat_down();
    },
    on_find_people_click() {
      this.$store.commit('chat/toggle');
      if(!this.$store.getters['location/determined']) {
        store.commit('messages/add_message', {
          message: "chat.locationNotDetermined",
          level: "info"
        });
      }
    },
    update_last_visit() {
      consumer.send('chat:update_last_visit', {
        chat_room_id: this.$store.getters['chat/current_room'].id
      });
      this.$store.commit('chat/update_last_visit', {
        username: this.$store.getters['account/username'],
        room: this.$store.getters['chat/current_room']
      });
    },
    on_approve(data) {
      const chat_room = this.$store.getters['chat/room_for_target'](data.target);
      this.$store.commit('chat/approve', {chat_room});
    },
    on_reject(data) {
      this.$store.commit('chat/unset_current_room');
      const chat_room = this.$store.getters['chat/room_for_target'](data.target);
      this.$store.commit('chat/reject', {chat_room});
    },
    on_create(data) {
      if(this.$store.getters['chat/rooms'].length === 0 &&
          data.chat_room.target.from_user_username !== this.$store.getters['account/username']) {
        store.commit('messages/add_message', {
          message: "chat.newRequest",
          level: "info"
        });
      }
      this.$store.commit('chat/add_room', {chat_room: data.chat_room});
    },
    get_position(message) {
      return message.author === this.$store.getters['account/username'] ? 'justify-start' : 'justify-end';
    },
    get_bg_color(message) {
      return message.author === this.$store.getters['account/username'] ? 'bg-lightgray gray-border' :
          'bg-blue text-white';
    },
    change_status_of_current_room(action) {
      const chat_room = this.$store.getters['chat/current_room'];
      const target = chat_room.target;
      target.action = action;
      axios.post(`/account/friendship/update/`, {
        target: target
      });
    },
    go_to_user_detail() {
      const target = this.$store.getters['chat/current_room'].target;
      const username = this.$store.getters['chat/target_username'](target);
      const user = this.$store.getters['users/by_username'](username);
      if(user === null) {
        this.$store.commit('messages/add_message', {
          level: 'info',
          message: 'chat.testInProgress'
        });
        return;
      }
      this.$store.commit('users/set_selected_user', {user});
      this.$router.push('/user-detail');
      this.$store.commit('chat/toggle');
    }
  }
}
</script>