const character = {
    namespaced: true,

    state: () => ({
        //[[trait, question]]
        questions: null,
        //index
        current_question: null,
        //{trait: value}
        traits: null,
        reference_traits: null,
        selected_range_value: 3,
        categories: {
            //Neuroticism
            'anx': "anxiety",
            'ang': "anger",
            'dep': "depression",
            'con': "self-consciousness",
            'imm': "immoderation",
            'vul': "vulnerability",

            //Extraversion
            'fri': "friendliness",
            'gre': "gregariousness",
            'ass': "assertiveness",
            'act': "activity level",
            'exc': "excitement-seeking",
            'che': "cheerfulness",

            //Openness
            'ima': "imagination",
            'art': "artistic interests",
            'emo': "emotionality",
            'adv': "adventurousness",
            'int': "intellect",
            'lib': "liberalism",

            //Agreeableness
            'tru': "trust",
            'mor': "morality",
            'alt': "altruism",
            'coo': "cooperation",
            'mod': "modesty",
            'sym': "sympathy",

            //Conscientiousness
            'eff': "self-efficacy",
            'ord': "orderliness",
            'dut': "dutifulness",
            'ach': "achievement-striving",
            'dis': "self-discipline",
            'cau': "cautiousness"
        },
        big_five: {
            'Extraversion': ['fri', 'gre', 'ass', 'act', 'exc', 'che'],
            'Openness': ['ima', 'art', 'emo', 'adv', 'int', 'lib'],
            'Agreeableness': ['tru', 'mor', 'alt', 'coo', 'mod', 'sym'],
            'Conscientiousness': ['eff', 'ord', 'dut', 'ach', 'dis', 'cau'],
            'Neuroticism': ['anx', 'ang', 'dep', 'con', 'imm', 'vul']
        },
        categories_de: {
            "Anxiety": "Ängstlichkeit",
            "Anger": "Reizbarkeit",
            "Depression": "Depression",
            "Self-consciousness": "Soziale Befangenheit",
            "Immoderation": "Impulsivität",
            "Vulnerability": "Verletzlichkeit",
            "Friendliness": "Herzlichkeit",
            "Gregariousness": "Geselligkeit",
            "Assertiveness": "Durchsetzungsfähigkeit",
            "Activity level": "Aktivität",
            "Excitement-seeking": "Erlebnishunger",
            "Cheerfulness": "Frohsinn",
            "Imagination": "Fantasie",
            "Artistic interests": "Ästhetik",
            "Emotionality":
                "Gefühle",
            "Adventurousness": "Abenteuer",
            "Intellect": "Intellekt",
            "Liberalism": "Liberalität",
            "Trust":
                "Vertrauen",
            "Morality": "Moralität",
            "Altruism": "Altruismus",
            "Cooperation": "Kooperativität",
            "Modesty":
                "Bescheidenheit",
            "Sympathy": "Gutherzigkeit",
            "Self-efficacy": "Kompetenz",
            "Orderliness":
                "Ordentlichkeit",
            "Dutifulness": "Pflichtbewusstsein",
            "Achievement-striving": "Leistungsstreben",
            "Self-discipline": "Selbstdisziplin",
            "Cautiousness": "Besonnenheit"
        },
        big_five_de: {
            "Neuroticism": "Neurotizismus",
            "Extraversion": "Extraversion",
            "Openness": "Offenheit",
            "Conscientiousness": "Gewissenhaftigkeit",
            "Agreeableness": "Verträglichkeit"
        }
    }),

    mutations: {
        set(state, {character}) {
            if (character === null) {
                return;
            }
            for (const key of Object.keys(character)) {
                state[key] = character[key];
            }
        },
        delete(state) {
            state.current_question = -1;
            state.questions = null;
            state.traits = null;
        },
        change_current_question(state, {value}) {
            state.current_question += value;
            if (state.current_question < 0) {
                state.current_question = 0;
            } else if (state.current_question > state.questions.length) {
                state.current_question = state.questions.length;
            }
        },
        set_selected_range_value(state, {value}) {
            state.selected_range_value = value;
        }
    },

    getters: {
        current_question_text: (state) => (testIsOverText) => {
            if (state.current_question < state.questions.length) {
                return state.questions[state.current_question][1];
            }
            return testIsOverText;
        },

        selected_range_value: state => state.selected_range_value,
        questions: (state) => state.questions,
        current_question: (state) => state.current_question,
        current_question_trait: (state) =>
            state.questions[state.current_question][0],
        question_limit: (state) => state.questions.length,
        traits: (state) => state.traits,
        test_started: (state) => state.questions !== null,
        test_is_over: (state) =>
            state.questions && state.current_question >= state.questions.length,
        reference_traits: (state) => state.reference_traits,
        categories: state => state.categories,
        categories_de: state => state.categories_de,
        big_five: state => state.big_five,
        big_five_de: state => state.big_five_de,
        format_distance: () => (distance) => {
            return `${Math.round(distance / 1000)}km`;
        }
    },
};

export default character;
