<template>
  <Menu v-if="$store.getters['account/authenticated']"></Menu>
  <Messages></Messages>
  <Chat v-if="$store.getters['account/authenticated']"></Chat>
  <router-view/>
</template>

<script>
import Menu from './components/Menu.vue';
import Messages from './components/Messages.vue';
import Chat from './components/Chat.vue';

export default {
  components: {
    Menu,
    Messages,
    Chat
  }
};
</script>
