import {calculate_distance} from './location';

const users = {
    namespaced: true,

    /**
     * User: {
     *    username,
     *    location: {
     *      latitude?,
     *      longitude?,
     *      distance?
     *    },
     *    character_congruence,
     *    channel_name?,
     *    profile_text,
     *    profile_picture
     * }
     */
    state: () => ({
        users: [],
        selected_user: null
    }),

    mutations: {
        set(state, {users}) {
            for(let i = users.length-1; i >= 0; --i) {
                if(users[i].character_congruence === "test_not_completed") {
                    users.splice(i, 1);
                }
            }
            state.users = users;
        },

        set_distances(state, {latitude, longitude, max_distance}) {
            for (let i = state.users.length-1; i >= 0; --i) {
                let user = state.users[i];
                if (user.coordinates !== null) {
                    user.distance = calculate_distance(
                        user.coordinates[0],
                        user.coordinates[1],
                        latitude,
                        longitude
                    );
                    if(user.distance < max_distance) {
                        continue;
                    }
                }
                state.users.splice(i, 1);
            }
        },

        set_selected_user(state, {user, index}) {
            if (index !== undefined) {
                state.selected_user = state.users[index];
            } else {
                state.selected_user = user;
            }
        },

        sort(state, {key}) {
            state.users.sort((a, b) => key(a, b));
        }
    },

    getters: {
        all: state => state.users,
        selected_user: state => state.selected_user,
        calculated_distances: state => state.users.length > 0 && state.users[0].distance !== undefined,
        by_username: state => (username) => {
            for(const user of state.users) {
                if(user.username === username) {
                    return user;
                }
            }
            return null;
        }
    }
};

export default users;