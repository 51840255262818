const compare_character = {
    namespaced: true,
    state: () => ({
        max_distance: 100,
        congruence_filter_state: 0, //0 disabled, 1 descending, 2 ascending
        distance_filter_state: 2,
        selected_activities: {},
    }),
    mutations: {
        set_max_distance(state, { max_distance }) {
            state.max_distance = max_distance;
        },
        set_congruence_filter_state(state, { congruence_filter_state }) {
            state.congruence_filter_state = congruence_filter_state;
        },
        set_distance_filter_state(state, { distance_filter_state }) {
            state.distance_filter_state = distance_filter_state;
        },
        set_selected_activities(state, { selected_activities }) {
            state.selected_activities = selected_activities;
        }
    },
    getters: {
        max_distance: state => state.max_distance,
        congruence_filter_state: state => state.congruence_filter_state,
        distance_filter_state: state => state.distance_filter_state,
        selected_activities: state => state.selected_activities,
        distance_upper_bound: () => 1000000
    }
};

export default compare_character;