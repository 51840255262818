<template>
  <div :class="containerClass">
    <img v-if="$store.getters['account/profile_picture']"
         :src="$store.getters['common/baseURL'] + $store.getters['account/profile_picture']"
         :style="{'height': radius + 'px', 'width': radius + 'px'}"
         class="mw-200 mx-auto d-block object-cover" style="border-radius: 50%;"/>
    <div v-else :style="{'height': radius + 'px', 'width':
         radius + 'px'}"
         class="bg-gray-300 flex items-center justify-center mx-auto" style="border-radius: 50%;">
      <i :style="{'font-size': fontSize + 'px'}" class="fas fa-user text-white"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['fontSize', 'radius', 'containerClass']
}
</script>