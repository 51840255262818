import websocketConsumer from "@/websocketConsumer";
import store from "@/store";
import router from "@/router";

export async function logout() {
      websocketConsumer.disconnect();
      store.commit('account/delete');
      await router.push('/login');
      store.commit('messages/remove');
      location.reload();
}