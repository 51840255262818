import axios from 'axios';
import store from './store';

function addErrorMessages(error) {
    if (!(error.response.data instanceof Array)) {
        return;
    }
    for (const error of error.response.data) {
        store.commit('messages/add_message', {message: error, level: 'error'});
    }

    return error.response;
}

const getClient = function () {
    let instance = axios.create();
    instance.defaults.withCredentials = true;

    instance.interceptors.request.use((value) => {
        store.commit('common/setWaitingForResponse', { status: true });
        return value;
    }, (value) => {
        store.commit('common/setWaitingForResponse', { status: false });
        throw value;
    });

    instance.interceptors.response.use((value) => {
        store.commit('common/setWaitingForResponse', { status: false });
        return value;
    }, (value) => {
        store.commit('common/setWaitingForResponse', { status: false });
        throw value;
    });

    if (process.env.VUE_APP_MODE === 'production') {
        instance.defaults.baseURL = 'https://yourcharacter.myactivities.net:8000/';

        instance.interceptors.response.use(null, addErrorMessages);
        instance.interceptors.request.use(function (config) {
            const token = store.getters['account/token'];
            config.headers.Authorization = token ? `Token ${token}` : '';
            return config;
        });

        return instance;
    }

    instance.defaults.baseURL = 'http://localhost:8000/';

    instance.get('/').catch(error => {
        if(!error.response) {
            instance.defaults.baseURL = 'http://192.168.0.26:8000/';
        }
    });

    instance.interceptors.request.use(function (config) {
        instance.stamp = new Date().getTime();
        const token = store.getters['account/token'];
        config.headers.Authorization = token ? `Token ${token}` : '';
        return config;
    });

    instance.interceptors.response.use(function (response) {
            console.log(`${response.request.responseURL}: ${new Date().getTime() - instance.stamp} ms`);
            console.log(response.data);
            return response;
        },
        function (error) {
            console.log(`${error.response.request.responseURL}: ${new Date().getTime() - instance.stamp} ms`);
            console.log(`Request error: ${JSON.stringify(error.response.data)}`);
            return addErrorMessages(error);
        }
    );

    return instance;
};

export default getClient();
