import axios from "@/axios";

const common = {
    namespaced: true,

    state: () => ({
        waitingForResponse: false,
        showPremiumModal: false,
        showPostPremiumModal: false
    }),

    mutations: {
        setWaitingForResponse(state, { status }) {
            if(status === false) {
                //The waitingForResponse variable is used to disable buttons that could
                //otherwise be misused by clicking them too fast. Enabling them right
                //after the request could lead to accidental clicking.
                setTimeout(() => {
                    state.waitingForResponse = status;
                }, 500);
                return;
            }
            state.waitingForResponse = status;
        },
        setShowPremiumModal(state, { status }) {
            state.showPremiumModal = status;
        },
        setShowPostPremiumModal(state, { value }) {
            state.showPostPremiumModal = value;
        }
    },

    getters: {
        baseURL: () => {
            return axios.defaults.baseURL.substring(0, axios.defaults.baseURL.length - 1);
        },
        waitingForResponse: state => state.waitingForResponse,
        showPremiumModal: state => state.showPremiumModal,
        showPostPremiumModal: state => state.showPostPremiumModal
    }
}

export default common;