import store from "./store";

class WebsocketConsumer {
    constructor() {
        this.debug = process.env.VUE_APP_MODE !== 'production';
        this.func_map = {};
        this.connect();
    }

    connect() {
        const url = this.get_url();
        if (url === null) {
            setTimeout(this.connect.bind(this), 500);
            return;
        }
        this.websocket = new WebSocket(url);
        this.websocket.onmessage = this.handle_message.bind(this);
        this.websocket.onclose = function (e) {
            console.log(
                "User socket closed unexpectedly. Attempting reconnect in 1 second. Event: ",
                e
            );
            setTimeout(this.connect.bind(this), 1000);
        }.bind(this);
    }

    disconnect() {
        if (this.websocket) {
            this.websocket.close();
        }
    }

    is_connected() {
        return this.websocket.readyState === WebSocket.OPEN;
    }

    get_url() {
        const token = store.getters["account/token"];
        if (!token) {
            return null;
        }
        let prefix = "ws";
        let host = 'localhost';
        if(process.env.VUE_APP_MODE === 'production') {
            host = 'yourcharacter.myactivities.net';
            prefix = "wss";
        }
        return prefix + "://" + host + ":8000/ws/user/" + token;
    }

    send(target, data = {}) {
        if(!this.websocket) {
            setTimeout(() => {
                this.send(target, data);
            }, 500);
            return;
        }
        if (this.websocket.readyState !== WebSocket.OPEN) {
            this.websocket.addEventListener("open", () => {
                this.send(target, data);
            });
            return;
        }

        data["category"] = target.split(":")[0];
        data["action"] = target.split(":")[1];
        this.websocket.send(JSON.stringify(data));
    }

    handle_message(payload) {
        const data = JSON.parse(payload.data);
        if(this.debug) {
            console.log("Received websocket payload:");
            console.log(data);
        }
        const target = `${data.category}:${data.action}`;
        this.func_map[target](data);
    }

    register(target, callback) {
        this.func_map[target] = callback;
    }
}

export default new WebsocketConsumer();
