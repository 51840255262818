import {createRouter, createWebHistory} from "vue-router";
import store from "./store.js";
import {makeBackgroundGray, makeBackgroundWhite} from "@/helpers/ui";
import {logout} from "@/helpers/account";

const router = new createRouter({
    mode: "history",
    history: createWebHistory(), routes: [{
        path: "/", redirect: "/login",
    }, {
        path: "/login", name: "login", component: () => import("./pages/LandingPage"),
    }, {
        path: "/register", name: "register", component: () => import("./pages/LandingPage"),
    }, {
        path: "/forgot-password", name: "forgot-password", component: () => import("./pages/LandingPage"),
    }, {
        path: "/legal-notice", name: "legal-notice", component: () => import("./pages/LandingPage")
    }
    ,{
        path: "/reset-password", name: "reset-password", component: () => import("./pages/LandingPage")
    }, {
        path: "/choose-test", name: "choose-test", component: () => import("./pages/ChooseTest.vue"),
    }, {
        path: "/test", name: "test", component: () => import("./pages/Test.vue"),
    }, {
        path: "/character-overview",
        name: "character-overview",
        component: () => import("./pages/CharacterOverview.vue"),
    }, {
        path: "/compare-characters",
        name: "compare-characters",
        component: () => import("./pages/CompareCharacters.vue"),
    }, {
        path: "/home", name: "home", component: () => import("./pages/Dashboard.vue"),
    }, {
        path: "/user-detail", name: "user-detail", component: () => import("./pages/UserDetail.vue"),
    }, {
        path: "/user-edit", name: "user-edit", component: () => import("./pages/UserEdit.vue")
    }, {
        path: "/activity-suggestions",
        name: "activity-suggestions",
        component: () => import("./pages/ActivitySuggestions.vue"),
    },],
});

router.afterEach((to) => {
    if(['login', 'register', 'forgot-password', 'legal-notice', 'reset-password', 'test'].indexOf(to.name) !== -1) {
        makeBackgroundWhite();
    }
    else {
        makeBackgroundGray();
    }
});

router.beforeEach((to, from, next) => {
    const route_requires_authentication = ["login", "register", "forgot-password", "reset-password", "legal-notice"].indexOf(to.name) === -1;
    if(store.getters['account/session_expired']) {
        logout().then(() => {
            store.commit('messages/add_message', {
                message: "login.autoLogout",
                level: "info"
            });
        });
        return;
    }

    if(['home', 'user-detail', 'user-edit', 'character-overview', 'compare-characters'].indexOf(to.name) !== -1) {
        if(window.innerWidth < 768) {
          document.body.classList.add('bg-lightgray');
        }
    }

    if (!store.getters["account/authenticated"]
        && route_requires_authentication) {
        return next({name: "login"});
    }
    if (store.getters["account/authenticated"]
        && !route_requires_authentication) {
        return next({name: "home"});
    }
    if(!store.getters['character/test_started']
        && route_requires_authentication
        && to.name !== 'choose-test'
        && to.name !== 'user-edit') {
        return next({name: 'choose-test'});
    }
    if(store.getters['character/test_started']
        && !store.getters['character/test_is_over']
        && route_requires_authentication
        && to.name !== 'test'
        && to.name !== 'user-edit') {
        return next({name: 'test'});
    }
    if(!store.getters['location/determined']
        && store.getters['character/test_is_over']
        && route_requires_authentication
        && to.name !== 'user-edit'
        && to.name !== 'home') {
        return next({name: 'home'});
    }
    if(store.getters['character/test_is_over']
        && to.name === 'test') {
        return next({name: 'home'});
    }

    return next();
});

export default router;
