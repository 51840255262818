export function calculate_distance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const phi1 = lat1 * Math.PI / 180;
    const phi2 = lat2 * Math.PI / 180;
    const dphi = (lat2 - lat1) * Math.PI / 180;
    const ds = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(dphi / 2) * Math.sin(dphi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(ds / 2) * Math.sin(ds / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // in metres
}

const location = {
    namespaced: true,

    state: () => ({
        latitude: null,
        longitude: null,
        city: null,
        county: null,
        state: null,
        country: null
    }),

    mutations: {
        set(state, {location}) {
            for (const key of Object.keys(state)) {
                state[key] = location ? location[key] : null;
            }
        }
    },

    getters: {
        determined: state => {
            for (const key of Object.keys(state)) {
                //independent cities have no county, this is alright.
                if (key === 'county') {
                    continue;
                }
                if (state[key] === null) {
                    return false;
                }
            }
            return true;
        },
        random_point() {
            const random = (min, max) => {
                Math.floor(Math.random() * (max - min + 1) + min);
            };
            const lat = random(-90, 89) + Math.random();
            const lon = random(-180, 79) + Math.random();
            return [lat, lon];
        },
        test_dist(state, getters) {
            let stamp = new Date().getTime();
            let N = 100000;
            let reference = getters.random_point;
            let others = [];
            for (var i = 0; i < N; ++i) {
                others.push(getters.random_point);
            }
            let distances = [];
            const setup_time = new Date().getTime() - stamp;
            stamp = new Date().getTime();
            for (let point in others) {
                distances.push(calculate_distance(reference[0], reference[1], point[0], point[1]));
            }
            alert(`Calculating distances to ${N} points took ${new Date().getTime() - stamp} ms. Setup-time: ${setup_time} ms`);
        },
        latitude: state => state.latitude,
        longitude: state => state.longitude
    }
}

export default location;