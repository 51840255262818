const chat = {
    namespaced: true,

    state: () => ({
        is_visible: false,
        current_room_id: null,
        /**
         * target: {
         *    from_user_username,
         *    to_user_username,
         *    status
         * },
         * messages: [{
         *    text,
         *    author,
         *    created_at
         * }],
         * members: [{
         *    username,
         *    last_visit
         * }]
         */
        chat_rooms: [],
        current_user_from_user_detail_page: ""
    }),

    mutations: {
        set(state, {chat_rooms}) {
            chat_rooms.sort((a, b) => {
                return new Date(b.messages[b.messages.length - 1].created_at) - new Date(a.messages[a.messages.length -1].created_at);
            });
            state.chat_rooms = chat_rooms;
        },
        set_current_user_from_user_detail_page(state, { username }) {
            state.current_user_from_user_detail_page = username;
        },
        toggle(state) {
            state.is_visible = !state.is_visible;
        },
        set_chat_visible(state) {
            state.is_visible = true;
        },
        set_current_room(state, {chat_room}) {
            state.current_room_id = chat_room.id;
        },
        unset_current_room(state) {
            state.current_room_id = null;
        },
        //messages: [{message, author, created}]
        add_room(state, {chat_room}) {
            state.chat_rooms.push(chat_room);
        },
        approve(state, {chat_room}) {
            chat_room.target.status = 'approved';
        },
        reject(state, {chat_room}) {
            const index = state.chat_rooms.indexOf(chat_room);
            if (index === -1) {
                throw `Room not found: ${JSON.stringify(chat_room)}`;
            }
            state.chat_rooms.splice(index, 1);
        },
        add_message(state, {message}) {
            const id = message.chat_room_id;
            for (const room of state.chat_rooms) {
                if (room.id === id) {
                    room.messages.push(message);
                    return;
                }
            }
            throw message;
        },
        update_last_visit(state, {room, username}) {
            for (const member of room.members) {
                if (member.username === username) {
                    member.last_visit = new Date().getTime();
                    return;
                }
            }
        }
    },

    getters: {
        new_messages_total: (state, getters) => {
            let count = 0;
            for (const room of state.chat_rooms) {
                if (getters.has_new_messages(room)) {
                    ++count;
                }
            }
            return count;
        },
        has_new_messages: (state, getters, rootState, rootGetters) => room => {
            if (room.members === undefined) {
                if (getters.can_approve_current_room(room)) {
                    return true;
                }
                return false;
            }
            const username = rootGetters['account/username'];
            let stamp;
            for (const member of room.members) {
                if (member.username === username) {
                    stamp = new Date(member.last_visit);
                    break;
                }
            }
            const last_message = room.messages[room.messages.length - 1];
            const date = new Date(last_message.created_at);

            return date > stamp && last_message.author !== username;
        },
        is_visible: state => state.is_visible,

        current_room: state => {
            if (state.current_room_id === null) {
                return null;
            }
            for (const room of state.chat_rooms) {
                if (room.id === state.current_room_id) {
                    return room;
                }
            }

            return null;
        },

        rooms: state => state.chat_rooms,

        can_approve_current_room: (state, getters, root_state, root_getters) => room => {
            if (room === undefined) {
                room = getters.current_room;
            }
            return room.target.to_user_username === root_getters['account/username'];
        },

        awaiting_approval_placeholder_label: (state, getters) => {
            if (getters.current_room.target.status === 'approved') {
                return 'chat.placeholder.nothing';
            }
            if (getters['can_approve_current_room']()) {
                return 'chat.placeholder.canApprove';
            } else {
                return 'chat.placeholder.awaitingApproval';
            }
        },

        current_room_approved: (state, getters) => {
            return getters.current_room.target.status === 'approved';
        },

        target_username: (state, getters, root_state, root_getters) => (target) => {
            if (target.type === 'Friendship') {
                return target.to_user_username === root_getters['account/username'] ? target.from_user_username : target.to_user_username;
            }
            throw "WTF";
        },

        target_picture: (state, getters, root_state, root_getters) => target => {
            if (target.type === 'Friendship') {
                const path = target.to_user_username === root_getters['account/username'] ? target.from_user_profile_picture : target.to_user_profile_picture;
                if(!path) {
                    return null;
                }
                return '/media/' + path;
            }
            throw "WTF";
        },

        room_for_target: (state, getters) => (target) => {
            const username = getters['target_username'](target);
            return getters['room_for'](username);
        },

        room_for: (state) => (username) => {
            for (const room of state.chat_rooms) {
                if (room.target.type === 'Friendship' &&
                    (room.target.to_user_username === username
                        || room.target.from_user_username === username)) {
                    return room;
                }
            }
            return null;
        },

        current_user_from_user_detail_page: state => state.current_user_from_user_detail_page
    }
};

export default chat;