import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";
import character from './modules/character';
import account from './modules/account';
import messages from './modules/messages';
import location from './modules/location';
import chat from './modules/chat';
import users from './modules/users';
import activity from './modules/activity';
import compare_character from "@/modules/compare_character";
import common from './modules/common';

export default createStore({
    plugins: [createPersistedState({
        key: 'mycharacter-vuex',
        paths: ['account', 'activity', 'character', 'chat', 'compare_character', 'location', 'messages', 'payment', 'users'],
        /*reducer(val) {
            //TODO: This is a problem for language switching.
            if(val.account.token === null) {
                return {};
            }
            return val;
        }*/
    })],
    modules: {
        account,
        activity,
        character,
        chat,
        location,
        messages,
        users,
        compare_character,
        common
    }
});
