const account = {
    namespaced: true,

    state: () => ({
        token: null,
        username: null,
        email: null,
        liked_activities: null,
        profile_text: null,
        profile_picture: null,
        location_string: null,
        available_languages: ['English', 'Deutsch'],
        selected_language: window.navigator.userLanguage || window.navigator.language === 'de'? 'Deutsch' : 'English',
        has_premium: null,
        login_timestamp: null
    }),

    mutations: {
        login(state, {user}) {
            for (const [key, value] of Object.entries(user)) {
                state[key] = value;
            }
            state.login_timestamp = new Date();
        },
        delete(state) {
            state.token = null;
            state.login_timestamp = null;
        },
        like_activity(state, {activity}) {
            state.liked_activities.push(activity.id);
        },
        undo_activity_like(state, {activity}) {
            state.liked_activities.splice(state.liked_activities.indexOf(activity.id), 1);
        },
        update_profile(state, {profile_text, profile_picture}) {
            state.profile_text = profile_text;
            if(!profile_picture) {
                return;
            }
            state.profile_picture = profile_picture;
        },
        set_selected_language(state, {language}) {
            state.selected_language = language;
        },
        set_location_string(state, {location_string}) {
            state.location_string = location_string;
        }
    },

    getters: {
        authenticated: (state) => Boolean(state.token),
        token: (state) => state.token,
        username: (state) => state.username,
        email: (state) => state.email,
        liked_activities: state => state.liked_activities,
        likes_activity: state => (activity) => {
            return state.liked_activities.indexOf(activity.id) !== -1;
        },
        location_string: state => state.location_string,
        profile_text: state => state.profile_text,
        profile_picture: state => state.profile_picture,
        selected_locale: state => {
            return {'English': 'en', 'Deutsch': 'de'}[state.selected_language];
        },
        available_languages: state => state.available_languages.filter(item => item !== state.selected_language),
        selected_language: state => state.selected_language,
        has_premium: state => state.has_premium,
        session_expired: state => {
            if(!state.login_timestamp) {
                return false;
            }
            const session_length_ms = 2*60*60*1000;
            return (new Date() - new Date(state.login_timestamp)) > session_length_ms;
        }
    },
};

export default account;
