<template>
  <div class="block md:hidden w-full mt-20"></div>
  <div id="menu"
       class="fixed md:relative flex w-full justify-around z-50 shadow-lg py-2 md:py-6 px-2 md:px-0 font-medium bg-white">
    <div class="flex md:ml-3 lg:ml-16 items-center flex-grow">
      <div class="cursor-pointer"
           @click="$store.getters['location/determined']? $router.push('/home') : ''">
        <img src="../assets/logo.png" style="height: 50px"/>
      </div>
      <div v-if="$store.getters['location/determined'] && $store.getters['location/determined']"
           class="hidden md:flex md:ml-10 items-center flex-grow">
        <router-link class="text-black no-underline ml-4" to="/home">{{ $t('menu.dashboard') }}</router-link>
        <router-link class="text-black no-underline ml-8" to="/choose-test">{{ $t('menu.newTest') }}</router-link>
        <router-link class="no-underline text-black ml-8" to="/activity-suggestions">{{
            $t('menu.activities')
          }}
        </router-link>
        <router-link class="ml-8 no-underline text-black" to="/compare-characters">{{ $t('menu.people') }}</router-link>
      </div>
      <div v-if="$store.getters['location/determined']"
           class="flex md:hidden flex-grow items-center justify-center">
        <div @click="onBarsTap"
             id="menu-dropdown-bars"
             class="text-2xl cursor-pointer"
             style="color: rgb(113	134	187	);">
          <i class="fas fa-bars"></i>
        </div>
        <div id="menu-dropdown-tooltip" class="tooltip bg-white rounded hidden py-3 px-4 text-black flex flex-col">
          <router-link @click="onBarsTap" class="text-black no-underline" to="/home">{{ $t('menu.dashboard') }}</router-link>
          <hr class="my-1.5"/>
          <router-link @click="onBarsTap" class="text-black no-underline" to="/choose-test">{{ $t('menu.newTest')
            }}</router-link>
          <hr class="my-1.5"/>
          <router-link @click="onBarsTap" class="no-underline text-black" to="/activity-suggestions">{{
              $t('menu.activities')
            }}
          </router-link>
          <hr class="my-1.5"/>
          <router-link @click="onBarsTap" class="no-underline text-black" to="/compare-characters">{{
              $t('menu.people') }}</router-link>
          <div class="arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
    <div class="flex md:mr-3 lg:mr-16 items-center cursor-pointer">
      <div class="relative" @click="$store.commit('chat/toggle')">
        <i class="fas fa-envelope text-3xl" style="color: rgb(209	209	209	)">
        </i>
        <div v-if="$store.getters['chat/new_messages_total']" class="bg-red absolute"
             style="width: 13px; height: 13px; border-radius: 50%; top: 0; right: -5px; border: 2px solid white"></div>
      </div>
      <div class="flex items-center cursor-pointer" @click="onNameTap">
        <ProfilePicture :container-class="'ml-8'" :font-size="30" :radius="60"></ProfilePicture>
        <div id="menu-username" class="ml-3">{{ $store.getters['account/username'] }}</div>
        <div v-if="!showingModal">
          <i class="fas fa-angle-down text-2xl ml-3"></i>
        </div>
        <div v-else>
          <i class="fas fa-angle-up text-2xl ml-3"></i>
        </div>
      </div>
    </div>

    <div id="menu-tooltip"
         class="absolute z-50 bg-white font-normal text-sm rounded-lg hidden"
         style="box-shadow: 0 0 15px 2px rgb(0 0 0 / 0.1)">
      <div class="mb-1 pr-8 pl-5 pt-2 pb-1 cursor-pointer"
           @click="userEdit">{{
          $t('menu.editAccount')
        }}
      </div>
      <hr v-if="$store.getters['character/test_is_over']" class="mx-2"/>
      <div class="mt-1 pr-8 pl-5 pb-2 pt-1 cursor-pointer" @click="logout">{{ $t('menu.logOut') }}</div>
    </div>
  </div>
</template>

<style>
.bg-red {
  background-color: rgb(192 59 52);
}
</style>

<script>
import {createPopper} from '@popperjs/core';
import router from "@/router";
import {logout} from "@/helpers/account";
import ProfilePicture from "@/components/ProfilePicture";
import { ref } from 'vue';

export default {
  name: "Menu",
  components: {ProfilePicture},
  setup() {
    const showingModal = ref(false);
    function onNameTap() {
      const tooltip = document.getElementById('menu-tooltip');
      const popper = createPopper(
          document.getElementById('menu-username'),
          tooltip,
          {
            placement: 'bottom',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 20]
                }
              }
            ]
          }
      );

      tooltip.classList.toggle('hidden');
      showingModal.value = !showingModal.value;
      popper.update();
    }

    function createTooltipCallback(func) {
      return async function () {
        await func();

        const tooltip = document.getElementById('menu-tooltip');
        if (!tooltip) {
          return;
        }
        showingModal.value = false;
        tooltip.classList.add('hidden');
      }
    }

    async function userEdit() {
      await router.push('/user-edit');
    }

    function onBarsTap() {
      const tooltip = document.getElementById('menu-dropdown-tooltip');
      const popper = createPopper(
          document.getElementById('menu-dropdown-bars'),
          tooltip,
          {
            placement: 'bottom',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10]
                }
              }
            ]
          }
      );

      tooltip.classList.toggle('hidden');
      popper.update();
    }

    return {
      onNameTap,
      onBarsTap,
      logout: createTooltipCallback(logout),
      userEdit: createTooltipCallback(userEdit),
      showingModal
    }
  }
}
</script>
