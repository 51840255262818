export function calc_score(score, reference, inverted) {
    let result;
    if (reference >= 50) {
        result = 50 / (100 - reference) * score + 100 - 5000 / (100 - reference);
    } else {
        result = 50 / reference * score;
    }
    if (result < 0) {
        result = 0;
    } else if (result > 100) {
        result = 100;
    }
    if (inverted) {
        return 100 - result;
    }
    return result;
}

export function calc_activity_score(activity, question_limit, traits, reference_traits) {
    if(!activity.trait_weights) {
        return null;
    }
    const max = 5 * question_limit / 30;
    const min = 1 * question_limit / 30;
    let score = 0;
    if (Object.keys(activity.trait_weights).length === 0) {
        return -1;
    }
    let max_score = 0;

    for (const [trait, weight] of Object.entries(activity.trait_weights)) {
        const percent = (traits[trait] - min) / (max - min) * 100;
        score += weight[1] * calc_score(percent, reference_traits[trait], weight[0] == 0);
        max_score += weight[1] * 100;
    }

    return Math.round(score / max_score * 100);
}
