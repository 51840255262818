import {calc_activity_score} from "@/helpers/character";

const activity = {
    namespaced: true,
    state: () => ({
        categories: null,
        activities: null
    }),

    getters: {
        categories: state => state.categories,
        activities: state => state.activities,
        activity_map: state => {
            let map = {};
            for (const activity of state.activities) {
                map[activity.id] = activity;
            }
            return map;
        },
        scores_calculated: state => {
            for (const activity of state.activities) {
                if (activity.score === undefined) {
                    return false;
                }
            }
            return true;
        }
    },

    mutations: {
        set(state, {activities, categories, locale}) {
            for (const activity of activities) {
                if(activity.trait_weights) {
                    for (const key of Object.keys(activity.trait_weights)) {
                        activity.trait_weights[key] = JSON.parse(activity.trait_weights[key]);
                    }
                }
                activity.name = activity.translations[locale].name;
                activity.translations = null;
            }

            state.activities = activities;

            for (const category of categories) {
                category.name = category.translations[locale].name;
                category.translations = null;
            }
            categories.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            state.categories = categories;
        },

        sort(state, {key}) {
            state.activities.sort((a, b) => key(a, b));
        },

        calculate_scores(state, {question_limit, traits, reference_traits}) {
            for (const activity of state.activities) {
                activity.score = calc_activity_score(activity, question_limit, traits, reference_traits);
            }
        },

        delete_scores(state) {
            for(const activity of state.activities) {
                activity.score = undefined;
            }
        }
    }
}

export default activity;